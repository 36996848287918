import { useState } from "react";
import { Container } from "react-bootstrap";
import useForm from "../hooks/useForm";
import { URL_VIDEO } from "../services/settings";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Footer } from "./Footer";
import { Loader } from "./Loader/Loader";
import { ImgLogo } from "./ImgLogo";
import ModalEmailValidation from "./Modal/ModalEmailValidation";

const init = {
  first_name: "",
  last_name: "",
  age: "",
  return_url: `${process.env.REACT_APP_APP_URL}/login`,
  email: "",
  password: "",
  password_confirmation: "",
  accepts_policy: false,
  accepts_consent: false,
};
export const RegisterForm = ({ checkActive = "true" }) => {
  const {
    data,
    setData,
    handleChange,
    handleCheckboxChange,
    handlePassTypeChange,
    inputPassType,
    post,
    clear,
  } = useForm(init);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [succes, setSucces] = useState(null);
  const [show, setShow] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/register`,

        data
      );
      if (response.status === 200) {
        setShow(true)
       
      }
    } catch (err) {
      if (err.response?.status === 422) {
        setErrors(err.response.data.errors);
      }
      if (err.response?.status === 500) {
        setSucces(false);
      }
    } finally {
      //clear();
      setLoader(false);
    }
  };

  return (
    <>
      <Container className="text-center ">
        <ImgLogo />
        <form className="row" onSubmit={handleSubmit}>
          <div className=" col-md-6 form-floating">
            <input
              type="text"
              onChange={handleChange}
              className="form-control"
              id="first_name"
              name="first_name"
              placeholder="Nombre"
              value={data.first_name}
              required
            />
            <label htmlFor="floatingInput">Nombre</label>
          </div>
          <div className=" col-md-6 form-floating">
            <input
              type="text"
              onChange={handleChange}
              className="form-control"
              id="last_name"
              name="last_name"
              placeholder="Apellido"
              value={data.last_name}
              required
            />
            <label htmlFor="floatingInput">Apellido </label>
          </div>
          <div className=" col-md-6 form-floating">
            <input
              type="date"
              onChange={handleChange}
              className="form-control"
              id="age"
              name="age"
              placeholder="Edad"
              value={data.age}
              required
            />
            <label htmlFor="floatingInput">Fecha de nacimiento* </label>
          </div>
          <div className=" col-md-6 form-floating">
            <input
              type="text"
              onChange={handleChange}
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              value={data.email}
              required
            />
            <label htmlFor="floatingInput">Email</label>
            {errors["correo electrónico"] && (
              <p className="text-danger text-start ">
                <p className="invalid error">{errors["correo electrónico"][0]}</p>
              </p>
            )}
          </div>
          <div className=" col-md-6 form-floating position-relative">
            <input
              type={inputPassType}
              onChange={handleChange}
              className="form-control"
              id="password"
              name="password"
              placeholder="Contraseña"
              value={data.password}
              required
            />
            <label htmlFor="floatingInput">Contraseña</label>
            <div className="btn-eye" onClick={handlePassTypeChange}>
              {inputPassType === "password" ? (
                <AiOutlineEyeInvisible />
              ) : (
                <AiOutlineEye />
              )}
            </div>
            {errors["contraseña"] && (
              <p className="text-danger text-start">{errors["contraseña"][0]}</p>
            )}
          </div>
          <div className=" col-md-6 form-floating position-realtive">
            <input
              type={inputPassType}
              onChange={handleChange}
              className="form-control"
              id="password_confirmation"
              name="password_confirmation"
              placeholder="Confirmar contraseña"
              value={data.password_confirmation}
              required
            />
            <label htmlFor="floatingInput">Confirmar contraseña</label>
            <div className="btn-eye" onClick={handlePassTypeChange}>
              {inputPassType === "password" ? (
                <AiOutlineEyeInvisible />
              ) : (
                <AiOutlineEye />
              )}
            </div>
            {errors["password_confirmation"] && (
              <p className="text-danger text-start">
                {errors["password_confirmation"][0]}
              </p>
            )}
          </div>

          {/* Acceptation check - visibility */}

          {checkActive === "true" && (
            <div className="col-12 form-check text-start ms-3 mt-3">
              <input
                className="form-check-input"
                onChange={handleCheckboxChange}
                type="checkbox"
                checked={data.accepts_policy}
                name="accepts_policy"
                id="accepts_policy"
                required
              />
              <label className="form-check-label fs-6" htmlFor="accepts_policy">
                Deseo darme de alta en el sitio web
                www.specellistanemiaaplasica.com y con este fin he leído y
                acepto sus
                <a
                  href={`${URL_VIDEO}/Condiciones_Generales_de_Uso.pdf`}
                  className="mx-2 nav-links"
                  target="_blank"
                  rel="noreferrer"
                >
                  Condiciones de Uso
                </a>
                y su
                <a
                  href={`${URL_VIDEO}/Política_de_Privacidad_General.pdf`}
                  className="mx-2 nav-links"
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de privacidad.
                </a>
              </label>
              {errors["accepts_policy"] && (
                <p className="invalid error">{errors["accepts_policy"][0]}</p>
              )}
            </div>
          )}
          {checkActive === "true" && (
            <div className="col-12 form-check text-start ms-3">
              <input
                className="form-check-input"
                onChange={handleCheckboxChange}
                type="checkbox"
                checked={data.accepts_consent}
                name="accepts_consent"
                id="accepts_consent"
              />
              <label
                className="form-check-label fs-6"
                htmlFor=" accepts_consent"
              >
                Deseo que LEN MAYO INTERNATIONAL me mantenga informado de sus
                productos y servicios y me remita comunicaciones comerciales por
                medios electrónicos, incluida mi dirección de correo
                electrónico.
              </label>
              {errors["accepts_consent"] && (
                <p className="invalid error">{errors["accepts_consent"][0]}</p>
              )}
            </div>
          )}

          <div className="my-4  text-center">
            {loader && <Loader />}
            <button className="btn__cell ">Registrarme</button>
          </div>
        </form>
      </Container>
      <ModalEmailValidation show={show} setShow={setShow}/>
      <Footer />
    </>
  );
};
