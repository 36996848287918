import { URL_IMG } from "../services/settings";
import { URL_VIDEO } from "../services/settings";

export const Footer = () => {
  return (
    <footer className="text-center mt-4">
      <section className="bg__transparent py-3 m-0 d-flex justify-content-center flex-wrap">
        <div className="box-img text-start d-flex flex-column mx-3">
          <small>Con el aval social de: </small>
          <img src={`${URL_IMG}/SEHH.png`} width="300" className="img-fluid" />
        </div>
        <div className="box-img text-start d-flex flex-column mx-3">
          <small>Con el aval de: </small>
          <img src={`${URL_IMG}/hpn.png`} width="45" className="img-fluid ms-3" />
        </div>
      </section>
      <nav className="d-flex flex-wrap justify-content-center gap-3 py-3">
        <a
          href={`${URL_VIDEO}/Condiciones_Generales_de_Uso.pdf`}
          className="mx-2 nav-links"
          target="_blank"
          rel="noreferrer"
        >
          Condiciones generales
        </a>
        <a
          href={`${URL_VIDEO}/Política_de_Privacidad_General.pdf`}
          className="mx-2 nav-links"
          target="_blank"
          rel="noreferrer"
        >
          Política de privacidad
        </a>
        <a
          href={`${URL_VIDEO}/Copyright.pdf`}
          className="mx-2 nav-links"
          target="_blank"
          rel="noreferrer"
        >
          © LEN MAYO INTERNATIONAL, S.A.U. 2023
        </a>
      </nav>
    </footer>
  );
};
