import React from 'react'
import Iframe from '../components/Iframe'

function Player() {
  const [token, setToken] = React.useState(null)

  React.useEffect(() => {
    setToken(localStorage.getItem('5con5_token'))
  },[])

  return (
    <>
        {/* <iframe src="https://lms.grupo-mayo.com/api/scorm/play/f1b5f823-bbba-4a58-862f-a3eef493f64b" frameborder="0"></iframe> */}
      {token && <Iframe src={`${process.env.REACT_APP_API_BASE_URL}/api/scorm/play/a823fa32-742e-4e22-a91a-1cce0eca121b`} headers={{
          Authorization: `Bearer ${token}`
        }} 
        className={'w-100 vh-100'}
      />}
    </>
  )
}

export default Player