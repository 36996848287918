import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";


export default function ModalEmailValidation({show, setShow}) {
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
const handleRedirect = ()=>{
  navigate("/login");
}

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose} className="alert__modal">
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>Revise su email para validar el registro</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRedirect}>
            OK
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}