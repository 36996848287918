import axios from "axios";
import { useState } from "react";

const useForm = (init) => {
  const [data, setData] = useState(init);
  const [inputPassType, setInputPassType] = useState("password");

  const handleChange = (e) => {
    setData({
      ...data,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };
  const handleCheckboxChange = (e) => {
    setData({
      ...data,
      [e.currentTarget.name]: e.currentTarget.checked,
    });
  };
  const handlePassTypeChange = () => {
    inputPassType === "password"
      ? setInputPassType("text")
      : setInputPassType("password");
  };
  const post = async (url, data = {}, config = {}) => {
    const response = await axios.post(url, data, config);
    return response;
  };
  const clear = () => {
    setData(init);
  };

  return {
    data,
    setData,
    handleChange,
    handleCheckboxChange,
    handlePassTypeChange,
    inputPassType,
    post,
    clear,
  };
};
export default useForm;
