import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { URL_VIDEO } from "../services/settings";
import { URL_IMG } from "../services/settings";
import { Footer } from "./Footer";

export const Main = () => {
  const videoEl = useRef();

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);
  return (
    <section className=" position-relative d-flex flex-column justify-content-center  mt-0 pt-0">
    <div className="logo-top">
      <img
        src={`${URL_IMG}/GRUPOMAYO.png`}
        width="180"
        className="img-fluid"
        alt="grupo mayo"
      />
    </div>

      <video
        playsInline
        loop
        muted
        alt="All the devices"
        src={`${URL_VIDEO}/pantalla_inici_logo_peq.mp4`}
        ref={videoEl}
        className="img-fluid"
      />
      <div className="box__content ">
      <div className="box-buttons ">

        <section className=" d-flex flex-wrap justify-content-center my-5 ">
          <Link to="/register" className="btn__cell my-1">
            Registro
          </Link>
          <Link to="/login" className="btn__cell my-1">
            Login
          </Link>
        </section>
      </div>
        <Footer />
      </div>
    </section>
  );
};
