import { Main } from "../components/Main";
import { RegisterForm } from "../components/RegisterForm";

export const Register = () => {
  return (
    <>
      <RegisterForm checkActive="true" />
    </>
  );
};
