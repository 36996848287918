import { useState } from "react";
import { Container } from "react-bootstrap";
import { URL } from "../services/settings";
import useForm from "../hooks/useForm";
import { ImgLogo } from "./ImgLogo";

const init = {
  email: "",
  return_url: `${process.env.REACT_APP_APP_URL}/resetmail`,
};

export const ResetPass = () => {
  const { data, handleChange, post } = useForm(init);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/password/forgot`, data);
      if (res.status === 200)
        setMessage("<p>Correo de reestablecimiento enviado</p>");
    } catch (err) {
      if (err.response.status === 422) {
        setMessage("<p>Ha ocurrido un error</p>");
        return;
      }
      setMessage("<p>Ha ocurrido un error</p>");
    }
  };

  return (
    <Container className="text-center">
      <ImgLogo/>
      <h2 className="blue">Reestablecer contraseña</h2>
      <div className="row justify-content-center p-5">
        <div className="col-8 text-center">
          <form onSubmit={handleSubmit}>
            <div className="text-start mb-5">
              <label htmlFor="email" className="mb-2">
                Email
              </label>
              <input
                type="text"
                id="email"
                onChange={handleChange}
                name="email"
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
            <button className="btn__cell ">Solicitar</button>
          </form>
        </div>
      </div>
    </Container>
  );
};
