import { Link } from "react-router-dom";
import { URL_IMG } from "../services/settings";

export const ImgLogo = () => {
  return (
    <Link to="/">
    <img
      src={`${URL_IMG}/speCELList_logo.svg`}
      width="600"
      className="img-fluid mb-4"
      alt="specellist logo"
    />
  </Link>
  )
}
