import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { LoginForm } from "./components/LoginForm";
import { Home } from "./pages/Home";
import { Register } from "./pages/Register";
import CookieConsent from "react-cookie-consent";
import { ResetPasword } from "./pages/ResetPasword";
import { ResetPassMail } from "./pages/ResetPassMail";
import Player from "./pages/Player";

function App() {
  return (
    <Layout>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/resetpassword" element={<ResetPasword />} />
          <Route path="/resetmail" element={<ResetPassMail />} />
          <Route path="/player" element={<Player />} />
        </Routes>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Utilizamos cookies propias y de terceros para prestarle el servicio del
        sitio web, analizar nuestros servicios y conocer sus hábitos de
        navegación.
        {/* Si consiente su instalación pulse “Aceptar cookies”, o
        también puede configurar sus preferencias pulsando “Configurar cookies”. */}
        {""} Más información en nuestra “Política de cookies”.
      </CookieConsent>
    </Layout>
  );
}

export default App;
