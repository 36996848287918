import React, { useState } from "react";
import { Container } from "react-bootstrap";
import useForm from "../hooks/useForm";
import { URL_IMG } from "../services/settings";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Footer } from "./Footer";
import Swal from "sweetalert2";

const init = {
  email: "",
  password: "",
};
export const LoginForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    data,
    setData,
    handleChange,
    handlePassTypeChange,
    inputPassType,
    post,
    clear,
  } = useForm(init);

  const [succes, setSucces] = useState(null);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/login`,
        data
      );
      if (response.status === 200) {
        localStorage.setItem("5con5_token", response.data.data.token);
        navigate("/player");
        // window.location.href =
        // // `https://specellistanemiaaplasica.com/api/scorm/play/f2ba6bf7-8896-4f63-8efc-5e9ae6b4a513`
        // `${process.env.REACT_APP_API_BASE_URL}/api/scorm/play/f2ba6bf7-8896-4f63-8efc-5e9ae6b4a513`
      }
      setSucces(true);
    } catch (err) {
      if (err.response?.status === 422) {
        if (err.response.data.message === "Email not validated") {
          setMessage("Porfavor, verifica tu e-mail");
        } else if (err.response.data.message === "Invalid credentials") {
          setMessage("El usuario o contraseña son incorrectos");
        } else {
          setMessage("Ha ocurrido un error");
        }
        setSucces(false);
      }
      // navigate("/register");
    }
  };

  React.useEffect(() => {
    if (!searchParams.get("data")) return;

    const token = searchParams.get("data");
    const decoded = atob(token);

    window
      .fetch(`${decoded}`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          Swal.fire({
            title: "Se ha verificado su cuenta",
            icon: "success",
            toast: true,
            position: "top",
            showConfirmButton: false,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Ha ocurrido un error con la verificación",
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
        });
      });
  }, [searchParams]);

  return (
    <>
      <Container className="text-center">
        <Link to="/">
          <img
            src={`${URL_IMG}/speCELList_logo.svg`}
            width="600"
            className="img-fluid mb-4"
            alt="specellist"
          />
        </Link>
        <form onSubmit={handleSubmit} className="row justify-content-center">
          <div className="col-md-8 mb-4">
            <h2 className="text-center">Iniciar sesión</h2>
          </div>
          {succes === false && (
            <p className="my-2 text-danger fw-bold text-center">{message}</p>
          )}
          <div className=" col-md-8 form-floating">
            <input
              type="text"
              onChange={handleChange}
              className="form-control"
              id="email"
              name="email"
              placeholder="Email"
              value={data.email}
              required
            />
            <label htmlFor="floatingInput">Email</label>
          </div>
          <div className=" col-md-8 form-floating position-relative">
            <input
              type={inputPassType}
              onChange={handleChange}
              className="form-control"
              id="password"
              name="password"
              placeholder="Contraseña"
              value={data.password}
              required
            />
            <label htmlFor="floatingInput">Contraseña</label>
            <div className="btn-eye" onClick={handlePassTypeChange}>
              {inputPassType === "password" ? (
                <AiOutlineEyeInvisible />
              ) : (
                <AiOutlineEye />
              )}
            </div>
          </div>

          <div className="mt-3 text-center d-flex flex-wrap justify-content-center gap-3">
            <Link to="/resetpassword" className="blue ">
              ¿Has olvidado tu contraseña?
            </Link>
            <Link to="/register" className="blue ">
              Crear una cuenta
            </Link>
          </div>

          <div className="my-5  text-center">
            {/* {loader && <Loader />} */}
            <button className="btn__cell ">Acceder</button>
          </div>
        </form>
      </Container>
      <Footer />
    </>
  );
};
