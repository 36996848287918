import React from 'react'
import { Container } from "react-bootstrap";
import { ImgLogo } from "./ImgLogo";
import { redirect, useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2'

export const ResetMail = () => {
  const [searchParams] = useSearchParams()
  const passwordRef = React.useRef(null)
  const [errors, setErrors] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const confirmPasswordRef = React.useRef(null)
  const token = searchParams.get('token')
  const email = searchParams.get('email')
  const navigate = useNavigate()

  
  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      token,
      email,
      password: passwordRef.current.value
    }
    window.fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/password/reset`, {
      headers: {
        'Content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(data => {
        if (data.errors?.password) {
          setErrors(data.errors.password[0])
          return
        }
        Swal.fire({
          icon: 'success',
          title: 'Su contraseña se ha cambiado',
          toast: true,
          position: 'top',
          showConfirmButton: false
        })
        setErrors(null)
        navigate('/login')
      })
      .catch(err => {
        setErrors('Ha ocurrido un error inesperado')
      })
      .finally(() => setLoading(false))
  }
  
  return (
    <Container className="py-5 text-center">
      <ImgLogo />
      <h2>Reestablecer contraseña</h2>

      <div className="row justify-content-center">
        <div className="col-4">
          {token || email ? (
            <form onSubmit={handleSubmit} className="text-center">
              <div className="form-group my-3">
                <label htmlFor="passowrd">Contraseña</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  ref={passwordRef}
                  className="form-control"
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="password_confirmation">Repetir contraseña</label>
                <input
                  ref={confirmPasswordRef}
                  type="password"
                  id="password_confirmation"
                  className="form-control"
                  name="password_confirmation"
                />
              </div>
              <p className='fw-bold text-danger'>{errors}</p>
            
              <input type="submit" value="Enviar" className="btn__cell mt-4" />
            </form>
          ) : <p>URL no válida</p>}
        </div>
      </div>
    </Container>
  );
};
